<template>
  <Title title="Recetas" :length="recipeList.data.length" />
  <ContentMain>
    <template v-slot:list>
      <Search
        v-if="!recipeList.isLoading && recipeList.data.length > 0"
        @update:search="recipeSearch"
      />

      <div class="content-grid-list-items scroll">
        <template v-if="recipeList.isLoading">
          <Skeleton
            v-for="(skeleton, index) in skeletons"
            :key="index"
            skeleton="item"
          />
        </template>

        <template
          v-else-if="!recipeList.isLoading && recipeList.data.length > 0"
        >
          <Item
            v-for="(recipe, index) in recipes"
            :key="index"
            :activated="recipeSelected.id === recipe.id"
            :number="index + 1"
            :name="recipe.nombre"
            @update:selectItem="selectedRecipe(recipe.id)"
            @update:deleteItem="toggleDeleteModal(true, recipe.id)"
          />
        </template>
        <!-- 
          /**
            *TODO:
            * * Components empty list and error loading data
          **/
        -->
      </div>
    </template>
    <template v-slot:form>
      <FormRecipeSkeleton v-if="recipeSelected.isLoading" />

      <template v-else-if="!recipeSelected.isLoading">
        <FormMainRecipe />

        <template v-if="!isNewRecipe">
          <RecipeState />
          <RecipeSteps />
          <RecipeIngredients />
          <Categories />
        </template>
      </template>
    </template>

    <!-- 
      /**
        *TODO:
        * * Components empty 
      **/
    -->
  </ContentMain>

  <ModalDelete
    v-if="isOpenDeleteModal"
    screen="receta"
    :step="deleteRecipeModalStep"
    :message="deleteRecipeMsg"
    @update:cancel="toggleDeleteModal(false)"
    @update:delete="deleteRecipe"
  />
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';

import useRecipeItem from '@/modules/cms/composables/recipes/useRecipeItem';
import useRecipe from '@/modules/cms/composables/recipes/useRecipe';

export default {
  components: {
    ModalDelete: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ModalDelete" */ '@/modules/cms/components/modals/ModalDelete.vue'
      )
    ),
    Title: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Title" */ '@/modules/cms/components/Title.vue'
      )
    ),
    ContentMain: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ContentMain" */ '@/modules/cms/components/ContentMain.vue'
      )
    ),
    Search: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Search" */ '@/modules/cms/components/Search.vue'
      )
    ),
    Item: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Item" */ '@/modules/cms/components/Item.vue')
    ),

    Skeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Skeleton" */ '@/modules/cms/components/skeletons/Skeleton.vue'
      )
    ),

    FormRecipeSkeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormRecipeSkeleton" */ '@/modules/cms/parts/recipes/FormRecipeSkeleton.vue'
      )
    ),
    FormMainRecipe: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormMainRecipe" */ '@/modules/cms/parts/recipes/FormMainRecipe.vue'
      )
    ),
    RecipeState: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "RecipeState" */ '@/modules/cms/parts/recipes/RecipeState.vue'
      )
    ),

    RecipeSteps: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "RecipeSteps" */ '@/modules/cms/parts/recipes/steps/RecipeSteps.vue'
      )
    ),
    RecipeIngredients: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "RecipeIngredients" */ '@/modules/cms/parts/recipes/ingredients/RecipeIngredients.vue'
      )
    ),
    Categories: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Categories" */ '@/modules/cms/parts/recipes/categories/Categories.vue'
      )
    ),
  },
  setup() {
    const store = useStore();
    const { addRecipe } = useRecipe();
    return {
      isNewRecipe: computed(() => store.getters['cmsRecipes/isRecipeNew']),
      ...useRecipeItem(),
      addRecipe,
    };
  },
};
</script>
