const defaultImagesPaths = {
  imagen: {
    file: null,
    localImage: '/images/emptys/sizes/imagen.svg',
  },
  imagen2: {
    file: null,
    localImage: '/images/emptys/sizes/imagen2.svg',
  },
  imagen3: {
    file: null,
    localImage: '/images/emptys/sizes/imagen3.svg',
  },
};

export default defaultImagesPaths;
