const baseUrl = process.env.VUE_APP_PATH;

const imagePaths = {
  recipe: {
    imagen: baseUrl + '/recetas/grandes/',
    imagen2: baseUrl + '/recetas/medianas/',
    imagen3: baseUrl + '/recetas/chicas/',
  },
  ingredients: baseUrl + '/ingredientes/',
};

export default imagePaths;
