export const formattingRecipeMain = ({ recipe, imagesRecipes }) => {
  const { detalles, autor, timeNumber, times, nombre, porciones } = recipe;

  const tiempo = timeNumber + ' ' + times;

  const {
    imagen: imagen_1,
    imagen2: imagen_2,
    imagen3: imagen_3,
  } = imagesRecipes.value;

  const formData = new FormData();
  formData.append('imagen', imagen_1.file);
  formData.append('imagen2', imagen_2.file);
  formData.append('imagen3', imagen_3.file);
  formData.append('nombre', nombre);
  formData.append('porciones', porciones);
  formData.append('tiempo', tiempo);
  formData.append('autor', autor);
  formData.append('detalles', detalles);

  return formData;
};
