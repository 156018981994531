import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import useAuthentication from '@/modules/auth/composables/useAuthentication';

const useRecipeItem = () => {
  const store = useStore();

  const currentRecipe = ref('');
  const isOpenDeleteModal = ref(false);
  const deleteRecipeModalStep = ref(1);
  const deleteRecipeMsg = ref('');
  const recipe_ID = ref('');

  onBeforeMount(() => {
    const { validateAuthentication } = useAuthentication();
    validateAuthentication('recipes');
  });

  const selectedRecipe = async (recipeID) => {
    store.commit('cmsRecipes/loadSelectedRecipe', true);
    store.commit('cmsRecipes/editRecipeIngredient', {
      idIngredient: null,
      idRecipeIngredient: null,
      unitList: [],
    });

    const resp = await store.dispatch(
      'cmsRecipes/showSelectedRecipe',
      recipeID
    );
    store.commit('cmsRecipes/loadSelectedRecipe', false);
    const { status } = resp;
    if (status) {
      const {
        receta,
        pasos_preparacion,
        listado_ingredientes,
        categorias,
      } = resp.selectedRecipe;

      const recipe = receta[0];
      const preparationSteps = pasos_preparacion;
      const recipeIngredients = listado_ingredientes;
      const recipeCategories = categorias;

      store.commit('cmsRecipes/showRecipeMain', {
        recipe,
        status,
        preparationSteps,
        recipeIngredients,
        recipeCategories,
      });
    } else {
      store.commit('cmsRecipes/showRecipeMain', { status, msg: resp.msg });
    }
  };

  const toggleDeleteModal = (isOpen, recipeID = '') => {
    isOpenDeleteModal.value = isOpen;

    if (recipeID) recipe_ID.value = recipeID;
  };

  const deleteRecipe = async () => {
    deleteRecipeModalStep.value = 2;
    const { msg } = await store.dispatch(
      'cmsRecipes/deleteRecipe',
      recipe_ID.value
    );

    deleteRecipeMsg.value = msg;
    setTimeout(() => {
      deleteRecipeModalStep.value = 3;

      setTimeout(() => {
        isOpenDeleteModal.value = false;
        deleteRecipeModalStep.value = 1;
        store.dispatch('cmsRecipes/updateRecipes');
      }, 1200);
    }, 1800);
  };

  return {
    skeletons: computed(() => store.getters['cmsRecipes/getRecipesSkeletons']),
    recipeList: computed(() => store.getters['cmsRecipes/getRecipesList']),
    recipeSelected: computed(
      () => store.getters['cmsRecipes/getRecipeSelected']
    ),
    recipes: computed(() =>
      store.getters['cmsRecipes/getRecipesByTerm'](currentRecipe.value)
    ),

    currentRecipe,
    recipeSearch: (value) => (currentRecipe.value = value),
    selectedRecipe,
    toggleDeleteModal,

    deleteRecipe,
    isOpenDeleteModal,
    deleteRecipeModalStep,
    deleteRecipeMsg,
  };
};

export default useRecipeItem;
