const selectedImage = ({ target, currentOption, images }) => {
  const file = target.files[0];
  const option = currentOption.value;

  if (!file) {
    images.value[option].localImage = `/images/emptys/sizes/${option}.svg`;
    images.value[option].file = null;
    return;
  }

  images.value[option].file = file;
  const fr = new FileReader();
  fr.onload = () => (images.value[option].localImage = fr.result);
  fr.readAsDataURL(file);
};

export default selectedImage;
