const timeSeparator = (timeArray) => {
  const array = timeArray.split(' ');
  let timeObj = {};
  timeObj.timeNumber = parseFloat(array[0]);

  if (array[1] === 'min') timeObj.times = 'minutos';
  else timeObj.times = array[1];

  return timeObj;
};

export default timeSeparator;
