import { ref, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';

import useRecipeItem from '@/modules/cms/composables/recipes/useRecipeItem';

import timeSeparator from '@/modules/cms/helpers/timeSeparator';
import selectedImage from '@/modules/cms/helpers/selectedImage';
import { formattingRecipeMain } from '@/modules/cms/helpers/formatting';

import imagePaths from '@/modules/cms/data/imagePaths';
import defaultImagesPaths from '@/modules/cms/data/defaultImagesPaths';

const useRecipe = () => {
  const store = useStore();
  const currentMainRecipe = ref({});
  const recipeimagePathss = imagePaths.recipe;

  const selectedRecipe = ref();
  const currentOption = ref('imagen');

  const updateInputImage = ref();
  const imagesRecipes = ref(defaultImagesPaths);

  const isOpenUpdateModal = ref(false);
  const updateRecipeModalStep = ref(1);
  const updateRecipeMsg = ref('');
  const updateRecipeStatus = ref(true);
  const { selectedRecipe: fnSelectedRecipe } = useRecipeItem();

  onBeforeMount(() => {
    resetImages();
    const { tiempo, ...data } = store.getters['cmsRecipes/getRecipeMain'];

    let time = { timeNumber: '', times: '' };
    if (tiempo) time = timeSeparator(tiempo);

    currentMainRecipe.value = { ...data, ...time };
  });

  const resetImages = () => {
    imagesRecipes.value = {
      imagen: {
        file: null,
        localImage: '/images/emptys/sizes/imagen.svg',
      },
      imagen2: {
        file: null,
        localImage: '/images/emptys/sizes/imagen2.svg',
      },
      imagen3: {
        file: null,
        localImage: '/images/emptys/sizes/imagen3.svg',
      },
    };
  };

  const changeImage = () => {
    const { options, selectedIndex } = selectedRecipe.value;
    currentOption.value = options[selectedIndex].value;
  };

  const handlerUploadImage = () => {
    updateInputImage.value.click();
  };

  const onSelectedImage = ({ target }) => {
    selectedImage({ target, currentOption, images: imagesRecipes });
  };

  const updateRecipe = async () => {
    isOpenUpdateModal.value = true;
    updateRecipeModalStep.value = 1;

    const recipe = currentMainRecipe.value;
    const recipeCurrent = formattingRecipeMain({ recipe, imagesRecipes });

    const { status, msg, recipeID } = await store.dispatch(
      'cmsRecipes/updateRecipeMain',
      recipeCurrent
    );

    updateRecipeModalStep.value = 2;
    updateRecipeMsg.value = msg;
    updateRecipeStatus.value = status;

    setTimeout(async () => {
      isOpenUpdateModal.value = false;
      await store.dispatch('cmsRecipes/updateRecipes', recipeCurrent);
      fnSelectedRecipe(recipeID);
    }, 3000);
  };

  const isAddLoading = ref(false);
  const addRecipeShowRecipeMsg = ref(false);
  const addRecipeMsg = ref('');
  const addRecipeStatus = ref('form-');

  const addRecipe = async () => {
    isAddLoading.value = true;

    const recipe = currentMainRecipe.value;
    const recipeCurrent = formattingRecipeMain({ recipe, imagesRecipes });
    const { status, msg } = await store.dispatch(
      'cmsRecipes/addRecipe',
      recipeCurrent
    );

    isAddLoading.value = false;
    addRecipeShowRecipeMsg.value = true;
    addRecipeMsg.value = msg;
    addRecipeStatus.value += status ? 'success' : 'error';

    setTimeout(async () => {
      addRecipeShowRecipeMsg.value = false;
      addRecipeMsg.value = '';
      addRecipeStatus.value = 'form-';

      if (status) await store.dispatch('cmsRecipes/updateRecipes');
    }, 3000);
  };

  return {
    showFabRecipe: computed(() => store.getters['cmsRecipes/showFabRecipe']),
    currentMainRecipe,
    recipeimagePathss,
    imagesRecipes,

    selectedRecipe,
    currentOption,

    updateInputImage,
    handlerUploadImage,
    onSelectedImage,
    changeImage,

    updateRecipe,
    isOpenUpdateModal,
    updateRecipeModalStep,
    updateRecipeMsg,
    updateRecipeStatus,

    addRecipeShowRecipeMsg,
    addRecipeMsg,
    addRecipeStatus,
    addRecipe,
    isAddLoading,
  };
};

export default useRecipe;
